import React from "react"
import { Box, Typography } from "@material-ui/core"
const FiltreKullanimAlani = props => {
  const { children, title, padding, align } = props
  return (
    <Box p={padding || 0}>
      <Typography component="h2" variant="h4">
        <Box fontWeight={500} textAlign={align || "left"}>
          {title || "Paslanmaz Filtre ve Elek Kullanım Alanları"}
        </Box>
      </Typography>

      {children || (
        <p className="lead">
          Birbirinden farklı ham madde ve malzemelerin elemesinde, filtreleme ya
          da süzgeç işlemlerinde paslanmaz filtre kullanılmaktadır. Özellikle
          istenilen gözenekde imalatının yapılması, yüksek sıcaklığa
          dayanabilmesi, su ve asidik ortamlarda çok rahatlıkla kullanılabilmesi
          ve üstün mekanik özellikleri bakımından dolayı paslanmaz filtre
          kullanımı yaygındır. Paslanmaz filtre sanayi, granit ve kuartz
          ocakları, tarım sanayi, makine sanayi,pislik tutucu olarak,
          endüstriyel mutfak sanayi, gübre sanayi, plastik ham madde sanayi,
          otomotiv sanayi, tekstil sanayi, makine sanayi, ilaç ve kimya
          sanayilerinde kullanılmaktadır.{" "}
        </p>
      )}
    </Box>
  )
}
export default FiltreKullanimAlani
