import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Lightbox from "react-image-lightbox"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"
import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const YTipiPaslanmazPislik = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: { eq: "filtre-galeri/ytipipisliktutucufiltre4.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(
        relativePath: { eq: "filtre-galeri/ytipipisliktutucufiltre.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(
        relativePath: { eq: "filtre-galeri/ytipipisliktutucufiltre1.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(
        relativePath: { eq: "filtre-galeri/ytipipisliktutucufiltre2.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim5: file(
        relativePath: { eq: "filtre-galeri/ytipipisliktutucufiltre3.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const thumbArray = ["resim2", "resim3", "resim4", "resim5"]
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
    data.resim5.childImageSharp.full.src,
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Y Tipi Hazır Paslanmaz Filtre Sistemleri"
        description="Y Tipi Paslanmaz Pislik Tutucu Filtre Firmamız  Y tipi pislik tutucu filtre imalatı yapmaktadır. Müşterinin ihtiyacına göre her gözde filtre imalatı yapılmaktadır. Komple paslanmaz olduğu için çevresel faktörlere daha dayanıklıdır.Y tipi pislik tutucu filtrelerin montajı kolay olduğu"
        keywords="y tipi paslanmaz filtre,hazır filtre sistemleri,304 kalite y tipi filtre,304 kalite y tipi pislik tutucu,304 kalite paslanmaz filtre,pislik tutucu,y tipi pislik tutucu,316 kalite y tipi pislik tutucu,y tipi pislik tutucu kullanım alanları,316 kalite y tipi paslanmaz filtre,parmak y tipi filtre,dn y tipi pislik tutucu filtre,316 paslanmaz filtre,304 paslanmaz filtre,y pislik tutucu,pislik tutucu filtre,Y Tipi Hazır Paslanmaz Filtre Sistemleri,y tipi pislik tutucu fiyatları,pislik tutucu fiyatları"
        url="y-tipi-paslanmaz-pislik-tutucu-filtre"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Box textAlign="center">
                  <Typography variant="h4" component="h1">
                    <Box fontWeight={500}>
                      Y Tipi Paslanmaz Pislik Tutucu Filtre
                    </Box>
                  </Typography>
                </Box>
                <Lead>
                  Firmamız <span>Y tipi pislik tutucu filtre</span> imalatı
                  yapmaktadır. Müşterinin ihtiyacına göre her gözde filtre
                  imalatı yapılmaktadır. Komple paslanmaz olduğu için çevresel
                  faktörlere daha dayanıklıdır.
                </Lead>
                <Lead>
                  <span>Y tipi pislik tutucu filtrelerin</span> montajı kolay
                  olduğu için hızlı bir şekilde filtrelemeye başlanabilmektedir.
                  304 kalite paslanmaz ve 316 kalite paslanmaz olmak üzere iki
                  çeşit paslanmaz y tipi pislik tutucu filtre bulunmaktadır.
                </Lead>
                <Lead>
                  Örnek olarak ; 1/2″ , 3/4″ , 1″ , 1 1/4″ , 1 1/2″ , 2″ , 2
                  1/2″ , 3″ , 4″parmak ölçülerinde bulunmaktadır.
                </Lead>
                <Lead>
                  Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" py={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} py={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    imgClass=""
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="y tipi pislik tutucu filtre"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box py={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              align="center"
              title="Y Tipi Paslanmaz Pislik Tutucu Filtre Kullanım Alanları"
            />
          </Box>
        </Paper>
      </Box>
      <Box py={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div onClick={() => openLightBox(index + 1)}>
                <LightboxImageWrapper
                  fluid={data[resim].childImageSharp.thumb}
                  imgClass=""
                  imgAlt="Y Tipi Paslanmaz Pislik Tutucu Filtre"
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default YTipiPaslanmazPislik
