import React from "react"
import Img from "gatsby-image"
import { ZoomIn } from "@material-ui/icons"
import { Paper } from "@material-ui/core"
const LightboxImageWrapper = ({ fluid, imgAlt, imgClass }) => {
  return (
    <Paper elevation={4} className="gallery-image-wrapper">
      <Img
        className={imgClass || "img-fluid MuiPaper-elevation5 MuiPaper-rounded"}
        fluid={fluid}
        loading="eager"
        fadeIn={false}
        alt={imgAlt || "Erez Kaynak Paslanmaz Filtre İmalatı"}
      />
      <div className="overlay">
        <ZoomIn />
      </div>
    </Paper>
  )
}
export default LightboxImageWrapper
